body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

dt {
    float: left;
    width: 200px;
    font-weight: bold;
}

.p-component {
    font-size: 14px;
}

.desc-table td:first-child {
    width: 200px;
    font-weight: bold;
    padding: 5px 0;
}

p {
    line-height: 24px;
}

.p-tabview-nav-link {
    background: #ffffff;
    border-color: #74b654 !important;
    color: #74b654 !important;
}


.p-button.p-button-secondary, .p-buttonset.p-button-secondary > .p-button, .p-splitbutton.p-button-secondary > .p-button, .p-fileupload-choose.p-button-secondary {
    color: #ffffff;
    background: #74b654 !important;
    border: 1px solid #74b654 !important;
}

.p-datatable.p-datatable-selectable .p-datatable-tbody > tr.p-selectable-row:not(.p-highlight):not(.p-datatable-emptymessage):hover {
    background: #b2dc9f !important;
}

.p-tabview .p-tabview-nav .p-tabview-ink-bar {
    z-index: 1;
    display: block;
    position: absolute;
    bottom: 0;
    height: 2px;
    background-color: #cccccc !important;
    transition: 500ms cubic-bezier(0.35, 0, 0.25, 1);
}

.p-tabview .p-tabview-panels {
    padding: 0 !important;
}

.search-field {
    width: 480px;
}

.title-column {
    width: 200px !important;
}

.p-datatable .p-datatable-tbody > tr > td {
    text-align: left;
    padding: 10px;
}